@import url('https://fast.fonts.net/lt/1.css?apiType=css&c=bbfe319c-1319-4ed3-88a6-6904534bc96d&fontids=793308,793320');
@font-face {
  font-family: 'Camphor';
  src: url('./assets/fonts/793308/1a75b1be-660a-4bbb-89eb-ac51af5dd23c.eot?#iefix');
  src:
    url('./assets/fonts/793308/1a75b1be-660a-4bbb-89eb-ac51af5dd23c.eot?#iefix') format('eot'),
    url('./assets/fonts/793308/7afb413d-536f-439c-bcca-ba53e24cda07.woff2') format('woff2'),
    url('./assets/fonts/793308/4eea462d-03c9-489a-a928-185525ee60b0.woff') format('woff'),
    url('./assets/fonts/793308/b2be4b07-b8c4-4d15-beaa-8e29cc0d9b5c.ttf') format('truetype');
}
@font-face {
  font-family: 'Camphor';
  font-weight: 700;
  src: url('./assets/fonts/793320/2dfbc407-f527-4d2b-82c8-5d38905b6741.eot?#iefix');
  src:
    url('./assets/fonts/793320/2dfbc407-f527-4d2b-82c8-5d38905b6741.eot?#iefix') format('eot'),
    url('./assets/fonts/793320/30a72170-8872-4260-8db3-dc070ad9d5ac.woff2') format('woff2'),
    url('./assets/fonts/793320/f4e7a76f-8831-4641-87b8-d45d17b148cc.woff') format('woff'),
    url('./assets/fonts/793320/7bfa40d6-635d-4d32-9ce5-4b58a1332d0d.ttf') format('truetype');
}
body {
  margin: 0;
  height: 100%;
  min-height: 100vh;
  /* overflow-x: hidden; if added, scroll bars wont come in list screens*/
  background-color: #ffffff;
  font-family: 'Camphor', 'Open Sans', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
textarea {
  font-family: 'Camphor', 'Open Sans', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.row-higlight {
  background-color: #fff7ee !important;
}
.row-higlight-profit {
  background-color: #557571 !important;
}

.row-label-green {
  color: #0cc572 !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.row-label-red {
  color: #f02f2f !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
}
.RaLayout-content {
  background-color: #e5e5e5;
}
.RaSidebar-fixed {
  position: fixed;
  height: 100%;
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background: #1a1a1a;
}
.dashboardIcons {
  fill: #5d98ff !important;
}
.sideMenuIcons {
  fill: #8cb3f7 !important;
}
.RaMenuItemLink-active {
  color: rgb(255, 255, 255) !important;
}
.MuiAppBar-root {
  transform: translateY(0px) !important;
  visibility: visible !important ;
}
.RaLayout-content {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.iconStyle {
  fill: #1a1a1a !important;
  border-radius: 26px;
  padding: 8px;
  background: #d1d1d1;
}
.MuiTabs-indicator {
  /* height: 2px !important; */
  background-color: #1a1a1a !important;
}
.MuiMenuItem-root:focus::after {
  background-color: #ffffff00 !important;
}
.datepickerStyle {
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  font-family: 'Camphor';
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  padding: 0px 8px;
}
.MuiIconButton-root:focus::after {
  background-color: #ffffff00 !important;
}

.styledGrid {
  align-items: center;
}
.fileActionStyle {
  border: 1px solid #d4ddeb;
  border-radius: 6px;
  padding: 32px;
  cursor: pointer;
}

.fileActionStyle:hover {
  border: 1px solid #1a1a1a;
  fill: #ffffff;
  background-color: #1a1a1a;
}

.documentIcons {
  border: 1px solid #d4ddeb;
  border-radius: 6px;
  padding: 10px;
}

.documentIcons:hover {
  border: 1px solid #1a1a1a;
  fill: #ffffff;
  background-color: #1a1a1a;
}

.MuiListItemText-primary {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  letter-spacing: 0.00938em !important;
  font-size: 0.875rem !important;
}

.marginTop32 > .MuiInput-root {
  margin-top: 52px;
}

.companyRef {
  cursor: pointer;
  color: #092cfe;
  cursor: pointer;
  font-weight: 800 !important;
  font-size: 1em !important;
  background: #abebed;
  text-align: center;
  border-radius: 10px;
  vertical-align: middle;
}
.companyRef:hover {
  color: #1a1a1a;
}
